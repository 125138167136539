<script setup lang="ts">
import { ref } from 'vue';

const isVisible = ref(localStorage.getItem('site-unlocked') !== 'true');
const password = ref('');
const passwordError = ref<string | undefined>(undefined);

const validatePassword = () => {
  passwordError.value = undefined;
  if (password.value === import.meta.env.VITE_SITE_PASSWORD) {
    localStorage.setItem('site-unlocked', 'true');
    isVisible.value = false;
  } else {
    passwordError.value = 'Incorrect password';
  }
};
</script>

<template>
  <BalModal
    :show="isVisible"
    :canHide="false"
    title="Enter the password to gain access to BurrBear"
  >
    <BalVStack align="stretch" spacing="md">
      <BalTextInput
        v-model="password"
        name="password"
        type="password"
        placeholder="Password"
      />
      <BalBtn :block="true" @click="validatePassword">Unlock</BalBtn>
      <BalAlert v-if="passwordError" type="error" title="">
        {{ passwordError }}
      </BalAlert>
    </BalVStack>
  </BalModal>
</template>